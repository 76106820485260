import React from "react";
import logo from "../../assets/rocket.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon } from '@fortawesome/free-solid-svg-icons'
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const HideNavbar = () => {
    const navBar = document.querySelector(".navbar");
    navBar.classList.toggle("open");
}

function setTheme(themeName) {
    localStorage.setItem('theme', themeName);
    document.documentElement.className = themeName;
}

// function to toggle between light and dark theme
function toggleTheme() {
    if (localStorage.getItem('theme') === 'theme-dark') {
        setTheme('theme-light');
    } else {
        setTheme('theme-dark');
    }
}

function getInitialDarkMode() {
    return localStorage.getItem('theme') === 'theme-dark';
}

// Immediately invoked function to set the theme on initial load
(function () {
   if (localStorage.getItem('theme') === 'theme-dark') {
       setTheme('theme-dark');
   } else {
       setTheme('theme-light');
   }
})();

const Navbar = () => {
    const [isDarkMode, setDarkMode] = React.useState(getInitialDarkMode());

    const toggleDarkMode = (checked: boolean) => {
        setDarkMode(checked);
        toggleTheme();
    };

    return (
        <header id="nav-bar">
            <a href="/#" onClick={() => HideNavbar()}>
                <div className="logo" id="menu">
                    <img src={logo} alt="logo" />
                    <span>Skyforce Anti Cheat</span>
                </div>
            </a>
            
            <ul className="navbar">
                <li><a onClick={() => HideNavbar()} href="/#">Home</a></li>
                <li><a onClick={() => HideNavbar()} href="/#services">Services</a></li>
                {/*<li><a onClick={() => HideNavbar()} href="/#pricing">Pricing</a></li>*/}
                <li><a onClick={() => HideNavbar()} href="/#preview">Preview</a></li>
                <li><a onClick={() => HideNavbar()} href="/blog">Blog</a></li>
                <li><a onClick={() => HideNavbar()} href="/contact">Contact</a></li>
                <li><a onClick={() => HideNavbar()} href="/#about-us">About</a></li>
            </ul>

            <div className="main-navbar">
                <DarkModeSwitch
                    checked={isDarkMode}
                    onChange={toggleDarkMode}
                    size={30}
                />

                <div id="nav-icon" onClick={() => {
                    const navBar = document.querySelector(".navbar");
                    navBar.classList.toggle("open");
                }}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </header>
    )
}

export default Navbar