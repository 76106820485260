import { React, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import LoadingButton from "../LoadingButton"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { TelegramIcon } from "../../assets/Icons";

import "../../pages/home/style.css"
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

const ContactForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);    

    const [captchaValue, setCaptchaValue] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onCaptchaChange = (value) => {
        setCaptchaValue(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        if (!captchaValue) {
            setSubmissionStatus("Please complete the CAPTCHA.");
            setIsLoading(false);
            return;
        }
    
        const formData = new FormData(e.target);
        const formValues = Object.fromEntries(formData.entries());
    
        try {
            const response = await axios.post('https://auth.skyforce.ac/submit-form', { 
                token: captchaValue,
                formData: formValues 
            });
    
            if (response.data.success) {
                // CAPTCHA verification succeeded. Here you can handle further form processing like storing form data in a database.
                setSubmissionStatus("Form submitted successfully.");
                document.getElementById("main-form").reset();
            } else {
                setSubmissionStatus("CAPTCHA verification failed. Please try again.");
            }
        } catch (error) {
            setSubmissionStatus("An error occurred while submitting the form. Please try again later.");
            console.error(error);
        }

        setIsLoading(false);
    }
    
    return (
        <div>
            <section className="contact-form-email">
                <h2>Contact us</h2>

                <a className="email-btn" href="mailto:contact@skyforce.ac">
                    <FontAwesomeIcon className="email-icon" icon={faEnvelope} />
                    Email
                </a>
            </section>

            <section className="contact-form-new">
                <h2>CONTACT</h2>
                <p>Submit your business proposal here. Questions or comments are welcome</p>

                <label>Select a contact:*</label>
                
                <form id="main-form" onSubmit={handleSubmit}>
                    <select name="type" required>
                        <option value="" disabled selected="selected">Select</option>
                        <option value="license">License inquiries</option>
                        <option value="support">User support</option>
                        <option value="other">Other</option>
                    </select>

                    <div className="input-row">
                        <div className="input-field">
                            <label>Name:*</label>
                            <input type="text" name="name" required />
                        </div>

                        <div className="input-field">
                            <label>Email:*</label>
                            <input type="email" name="email" required />
                        </div>
                    </div>

                    <label>Subject:*</label>
                    <input type="text" name="subject" required></input>

                    <label>Message:*</label>
                    <textarea rows="9" name="message" required></textarea>

                    <ReCAPTCHA className="captcha"
                        sitekey="6Lff_d8oAAAAAJvO0kozSGnQwVXjvLdBEVMuccdw"
                        onChange={onCaptchaChange}
                    />

                    <LoadingButton formName={"main-form"} clsName={"submit-btn"} type={"submit"} isLoading={isLoading} />
                </form>
                {submissionStatus && <p className="submission-status">{submissionStatus}</p>}
            </section>
        </div>
    )
}

export default ContactForm

/*
                <a className="email-btn" id="telegram-button" href="https://t.me/reveny1">
                    <FontAwesomeIcon className="telegram-icon" icon={faTelegram} />
                    Telegram
                </a>
*/