import React from "react";
import { LogoIcon } from "../../assets/Icons"

const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <div className="logo-wrapper">
                    <div className="logo">
                        <LogoIcon />
                        <span><a className="links" href="/">Skyforce Anti-Cheat Blog</a></span>
                    </div>
                </div>
            </div>
        </header>
    )
}

//                <button className="icon">Back to skyforce.ac</button>

export default Header