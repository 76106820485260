import React, { useEffect } from "react";
import "./style.css"

import Navbar from "../../compontents/home/Navbar";
import Footer from "../../compontents/home/Footer";
import Header from "../../compontents/home/Header";
import Service from "../../compontents/home/Service";
import Pricing from "../../compontents/home/Pricing";
import Features from "../../compontents/home/Features";
import FAQ from "../../compontents/home/FAQ";
import About from "../../compontents/home/About";
import Contact from "../../compontents/home/Contact";
import Preview from "../../compontents/home/Preview";
import Stats from "../../compontents/home/Stats"
import Engines from "../../compontents/home/Engines";
import WOW from "wowjs"

const Home = () => {
    //Initialize WOWJS
    useEffect(()=>{
        new WOW.WOW().init();
    }, [])

    return (
        <div className="layout">
            <Navbar />

            <Header />
            <Service />
            <Pricing />
            <Features />
            <Engines />
            <FAQ />
            <Preview />
            <About />
            <Stats />
            <Contact />

            <Footer />
        </div>
    )
}

export default Home