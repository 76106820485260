import { useParams, Navigate } from "react-router-dom";
import List from "../../assets/posts.json"

import SetTitle from "../../compontents/SetTitle";
import MarkdownBlog from "../../compontents/blog/Markdown";
import Navbar from "../../compontents/home/Navbar";
import Footer from "../../compontents/home/Footer";

const Post = (props) => {
    const { id } = useParams(); 
    const validId = parseInt(id);

    if (!validId) {
        return <Navigate to="/blog/404" replace={true} />
    }

    const fetchedPost = {}
    let postExists = false
    List.forEach((post, i) => {
        if (validId === post.id) {
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.searchtitle = post.searchtitle ? post.searchtitle : post.title
            fetchedPost.url = post.url ? post.url : "No url given"
            fetchedPost.preview = post.preview ? post.preview : ""
            fetchedPost.content = post.content ? post.content : "No content given"
            postExists = true
        }
    })

    if (postExists === false) {
        return <Navigate to="/blog/404" replace={true} />
    }

    //Load post from file
    const mdFilePath = fetchedPost.url;

    return (
        <div>
            <SetTitle title={fetchedPost.searchtitle} />
            <Navbar />

            <div className="post-header">
                <div className="image-container">
                    <img className="post-header-img" src={fetchedPost.preview} alt="header_img" />
                    <h1>{fetchedPost.title}</h1>
                </div>
            </div>

            <div className="blog-container">
                <div class="blog-content">
                    <MarkdownBlog mdFilePath={mdFilePath}/>
                </div>
            </div>

            <button class="back-button" onClick={() => {
                window.history.back();
            }}>Back</button>

            <Footer />
        </div>
    )
}

export default Post