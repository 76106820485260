import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterUp = ({ target, unit }) => {
    const [counterOn, setCounterOn] = useState(false)

    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)}>
            {counterOn && <CountUp start={0} end={target} duration={1} delay={0} />}{unit}
        </ScrollTrigger>
    )
}

export default CounterUp