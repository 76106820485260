import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faTwitter, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faX } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="social">
                <a href="https://www.youtube.com/@Skyforce-AC" target="noopener"><FontAwesomeIcon icon={faYoutube}/></a>
                <a href="https://twitter.com/SkyforceAC" target="noopener"><FontAwesomeIcon icon={faXTwitter}/></a>
                <a href="https://www.linkedin.com/showcase/skyforce-anticheat" target="noopener"><FontAwesomeIcon icon={faLinkedin}/></a>
            </div>

            <ul className="list">
                <li><a href="/#">Home</a></li>
                <li><a href="/#services">Services</a></li>
                {/*<li><a href="/#pricing">Pricing</a></li>*/}
                <li><a href="/#about-us">About</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/terms">Terms</a></li>
            </ul>
            <p className="copyright"><a href="https://bytetrace.net" target="_blank">ByteTrace Technologies</a> &copy;2024</p>
        </footer>
    )
}

export default Footer