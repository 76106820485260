import React from "react";
import unityLogo from "../../assets/unity.svg";
import unrealLogo from "../../assets/unreal-engine.svg";
import godotLogo from "../../assets/godotengine.svg";
import customEngineLogo from "../../assets/custom_engine.svg";

const Engines = () => {
    return (
        <section className="engines" id="engines">
            <h2 className="engines-title">Supported Engines</h2>
            <div className="engines-container">
                <div className="engine-item">
                    <img src={unityLogo} className="svg-icon" alt="Unity Engine" />
                    <p>Unity Engine</p>
                </div>
                <div className="engine-item">
                    <img src={unrealLogo} className="svg-icon" alt="Unreal Engine" />
                    <p>Unreal Engine</p>
                </div>
                <div className="engine-item">
                    <img src={godotLogo} className="svg-icon" alt="Godot Engine" />
                    <p>Godot Engine</p>
                </div>
                <div className="engine-item">
                    <img src={customEngineLogo} className="svg-icon" alt="Custom Engines" />
                    <p>Custom Engines</p>
                </div>
            </div>
        </section>
    );
}

export default Engines;
