import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired, faMobile, faCrutch, faBug, faShield, faRectangleXmark, faCode } from '@fortawesome/free-solid-svg-icons'

const Service = () => {
    return (
        <section className="services" id="services">
            <div className="services-container">
                <h1>Our Services</h1>
                {/*<h1>Our Services</h1>*/}
                <div className="services-row">
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faCrutch} />
                        <h2>Block Code Injection</h2>
                        <p>By constantly monitoring game memory and analyzing code behavior, the android anti cheat can detect and flag any attempts of code injection.</p>
                    </div>
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faBug} />
                        <h2>Detect Debuggers</h2>
                        <p>The anti cheat feature can detect any attempted interaction with the game by debuggers or other unauthorized sources, providing a secure environment for your code.</p>
                    </div>
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faMobile} />
                        <h2>Block Dangerous Devices</h2>
                        <p>By blocking modified/rooted devices, the integrity of the app can be protected from third party apps</p>
                    </div>
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faShield} />
                        <h2>Block Unauthorized Code</h2>
                        <p>The android anti cheat can detect and prevent unauthorized code from executing, maintaining the apps integrity.</p>
                    </div>
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faRectangleXmark} />
                        <h2>Auto Blacklist</h2>
                        <p>Once a cheat is detected, it will be automatically blocked from all games, ensuring a consistent and fair gameplay experience for all protected games.</p>
                    </div>
                    <div className="service wow fadeIn">
                        <FontAwesomeIcon className="service-icon" icon={faCode} />
                        <h2>Detect Hacking Apps</h2>
                        <p>The anti cheat feature can detect and flag hacking apps, keeping gameplay fair and secure.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service