import React, { useState } from "react";
import Question from "../home/Question";

const FAQ = () => {
    const [data, setData] = useState ([
        {
            question: "Is this anti-cheat really the best one on the market?",
            answer: "Yes, our android anti cheat is the best one on the market. It is developed by experienced professionals with deep knowledge of cheat behavior and detection.",
            open: false
        },
        {
            question: "How does the anti-cheat impact game developers?",
            answer: "Our android anti cheat is designed to protect game developers by maintaining the integrity of their games and preventing cheating. By reducing cheating, the anti cheat can also improve player satisfaction and retention.",
            open: false
        },
        {
            question: "Will the anti-cheat affect game performance?",
            answer: "Our android anti cheat is designed to have minimal impact on game performance and we regularly optimize our system to ensure the best possible experience for players.",
            open: false
        },
        {
            question: "Is the anti-cheat effective against all types of cheats?",
            answer: "While our android anti cheat can detect and prevent many types of cheats, there is always the possibility that new cheats will be developed. We are constantly updating and improving our system to stay ahead of cheaters.",
            open: false
        },
        {
            question: "How does the anti-cheat handle privacy concerns?",
            answer: "We take privacy concerns very seriously and are committed to protecting the personal data of our users. Our anti cheat system is designed to collect only the data necessary to detect cheats.",
            open: false
        },
        {
            question: "Can any of the features be turned off by the game developer?",
            answer: "Yes, game developers have the option to customize and enable/disable specific features of our anti cheat system as per their requirements.",
            open: false
        },
        {
            question: "How does the cheat analysis feature work?",
            answer: "We perform manual analyses of new public cheats and promptly update our anti cheat solution accordingly, ensuring effective protection against evolving cheating techniques in games.",
            open: false
        }
    ])

    const toggleFAQ = index => {
        setData(data.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }
    
            return faq
        }))
    }

    return (
        <section className="faq wow slideInUp" id="faq">
            <h2 className="faq-title">Frequently Asked Questions</h2>

            <ul className="faq-list" id="faq-list">
                {data.map((currentElement, i) => {
                    return <Question currentElement={currentElement} index={i} toggle={toggleFAQ}/>
                })}
            </ul>
        </section>
    )
}

export default FAQ