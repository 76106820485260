import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx"
import Code from "./Code";
import "../../pages/home/style.css"

const MarkdownBlog = ({ mdFilePath }) => {
    const [markdownContent, setMarkdownContent] = useState('');
  
    useEffect(() => {
      const fetchMarkdown = async () => {
        try {
          const response = await fetch(`${process.env.PUBLIC_URL}${mdFilePath}`);
          const data = await response.text();
          setMarkdownContent(data);
        } catch (error) {
          console.error('Error loading Markdown file:', error);
        }
      };
  
      fetchMarkdown();
    }, [mdFilePath]);
  
    return (
        <article className="article">
            <div className="blog-container">
                <div className="post-wrapper">
                    <Markdown options={{
                        overrides: {
                            Code: {
                                component: Code
                            }
                        }
                    }}>
                        {markdownContent}
                    </Markdown>
                </div>
            </div>
        </article>
    )
  };

export default MarkdownBlog
