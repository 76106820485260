import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const Question = ({currentElement, index, toggle}) => {
    return (
        <li class="faq-item">
            <label>
                <div style={currentElement.open ? {color: "#1a91ff"} : {}}>{currentElement.question}</div> 
                <button className="btn" onClick={() => toggle(index)}>
                    {currentElement.open ? <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>}
                </button>
            </label>
            <div class={"content " + (currentElement.open ? 'active' : '')}>
                <p>
                    {currentElement.answer}
                </p>
            </div>
        </li>
    )
}

export default Question