import React from 'react';

import ContactPage from './pages/home/Contact';
import AboutPage from './pages/home/About';
import Blog from './pages/blog/Blog';
import Post from './pages/blog/Post'
import Terms from './pages/terms/Terms';
import BlogNotFound from './pages/blog/NotFound';

import Home from "./pages/home/Home"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { render } from "react-dom";

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path='/contact' element={<ContactPage/>} />
            <Route path="/terms" element={<Terms/>} />

            <Route path="/blog" element={<Blog/>} />
            <Route path="/blog/post/:id" element={<Post/>} />

            <Route path="/blog/404" element={<BlogNotFound/>} />
        </Routes>
    </BrowserRouter>,
    rootElement
);