import React, { useEffect } from "react";
import "./style.css"

import Navbar from "../../compontents/home/Navbar";
import Footer from "../../compontents/home/Footer";

import WOW from "wowjs"
import ContactForm from "../../compontents/home/ContactForm";

const ContactPage = () => {
    //Initialize WOWJS
    useEffect(()=>{
        new WOW.WOW().init();
    }, [])

    return (
        <div className="layout">
            <Navbar />

            <ContactForm />

            <Footer />
        </div>
    )
}

export default ContactPage