import React from "react";
import List from "../../assets/posts.json"
import Markdown from "markdown-to-jsx"
import { Link }  from "react-router-dom"

const PostList = () => {
    return (
        <div className="postlist">
            <h1 className="title">Blog Posts & Changelog</h1>

            {List.length && 
                List.map((post, i) => {
                    return (
                        <div key={i} className="post-card">
                            <h2 className="links-text"><Link className="links" to={`/blog/post/${post.id}`}>{post.title}</Link></h2>
                            <img width="560" height="315" src={post.preview} alt="preview"></img>
                            <Markdown className="blog-preview">
                                {post.content}
                            </Markdown>
                            <span><Link className="links-read-more" to={`/blog/post/${post.id}`}>Read more</Link></span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PostList