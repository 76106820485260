import React from "react";

import Navbar from "../../compontents/home/Navbar";
import Footer from "../../compontents/home/Footer";

import MarkdownBlog from "../../compontents/blog/Markdown";

const Terms = () => {
    return (
        <div className="layout">
            <Navbar />

            <div className="terms-container">
                <h1>Terms and Conditions</h1>

                <div class="terms-content">
                    <MarkdownBlog mdFilePath={"/terms.md"}/>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Terms