import React from "react";

import TypeWriterEffect from 'react-typewriter-effect';

const Header = () => {
    const root = document.documentElement;
    const style = getComputedStyle(root);
    const color = style.getPropertyValue('--primary-color-text').trim();

    return (
        <div className="hero-section">
            <h2 className="hero-title">Skyforce Anti-Cheat</h2>
            <p className="hero-subtitle">The future of mobile game protection</p>
            <div className="buttons-container">
                <a href="/contact" className="button-solid">Contact us</a>
                <a href="#features" className="button-outline">View Features</a>
            </div>
        </div>
    );
}

export default Header