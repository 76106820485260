// LoadingButton.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingButton = ({ buttonType, isLoading, clsName }) => {
    return (
        <button className={clsName} type={buttonType} disabled={isLoading}>
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
        </button>
    );
};

export default LoadingButton;
