import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons'

const Features = () => {
    return (
        <section className="features" id="features">
            <div className="feature-container">
                <h2>Compare our Plans</h2>
                
                <table>
                    <thead>
                        <tr className="wow fadeIn">
                            <th>Feature</th>
                            <th>Basic</th>
                            <th>Standard</th>
                            <th>Enterprise</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="wow fadeIn">
                            <td>Integrity Verification</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Runtime Checks</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Hooking Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Debugger Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Virtualization Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Xposed Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Root Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Cheating App Detection</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Block Overlays</td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Game Guardian Detection</td>
                            <td ><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Magisk Module Detection</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Root Hide Detection</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Hidden Library Detection</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Cheat analysis</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Engine Protection **</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                        <tr className="wow fadeIn">
                            <td>Metadata Encryption **</td>
                            <td><FontAwesomeIcon className="unchecked" icon={faX} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                            <td className="checked"><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                    </tbody>
                </table>
                <p>** Only works on games based on the Unity Engine</p>
            </div>
        </section>
    )
}

export default Features