import { React, useEffect} from "react";

import Navbar from "../../compontents/home/Navbar";
import Footer from "../../compontents/home/Footer";
import List from "../blog/PostList";
import "../home/style.css";

const Blog = () => {
    useEffect(() => {
        document.title = 'Blog - Skyforce AntiCheat';
    }, []);

    return (
        <div className="blog-layout">
            <Navbar />
                <List/>
            <Footer />
        </div>
    )
}

export default Blog