import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Pricing = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact');
    };

    /*
    return (
        <section className="pricing" id="pricing">
            <div className="container">
                <h2>Choose your plan</h2>
                <div className="price-row">
                    <div className="price-col wow slideInLeft" data-wow-duration="0.5s">
                        <p className="exclusive">Basic</p>
                        <ul>
                            <li>Basic protection</li>
                            <li>Runtime detections</li>
                            <li>Integrity verification</li>
                            <li>Limited support</li>
                        </ul>
                        <button onClick={handleClick}>Contact us</button>
                    </div>
                    <div className="price-col center wow slideInUp" data-wow-duration="0.5s">
                        <p className="exclusive">Standard</p>
                        <ul>
                            <li>Advanced protection</li>
                            <li>Beta access</li>
                            <li>Exclusive features</li>
                            <li className="last-point"></li>
                        </ul>
                        <button onClick={handleClick}>Contact us</button>
                    </div>
                    <div className="price-col wow slideInRight" data-wow-duration="0.5s">
                        <p className="exclusive">Enterprise</p>
                        <ul>
                            <li>All features</li>
                            <li>Beta access</li>
                            <li>Exclusive customisation</li>
                            <li className="last-point"></li>
                        </ul>
                        <Link to="/contact">
                            <button>Contact us</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
    */

    return (
        <section className="new-pricing">
            <div class="new-pricing-container">
                <h2 class="new-pricing-header">Pricing</h2>
                <p class="new-pricing-description">Everything you need to continuously block cheaters in your games.</p>
                
                <div class="new-pricing-card-container">
                    <div class="new-pricing-card">
                        <div class="new-pricing-card-header">
                            <div class="new-pricing-card-title">Free tier</div>
                        </div>
                        <div class="new-pricing-card-content">
                            <div class="new-pricing-card-subtitle">Small indie game</div>
                            <div class="new-pricing-card-text">Up to 100 CCU for unlimited time. Ideal for small indie developers who need basic protection.</div>
                        </div>
                        <a href="/contact" class="new-pricing-card-button">Contact Sales</a>
                    </div>

                    <div class="new-pricing-card">
                        <div class="new-pricing-card-header">
                            <div class="new-pricing-card-title">Paid tier</div>
                        </div>
                        <div class="new-pricing-card-content">
                            <div class="new-pricing-card-subtitle">Bigger game studios</div>
                            <div class="new-pricing-card-text">Exclusive features, priority support, free during closed beta-testing.</div>
                        </div>
                        <a href="/contact" class="new-pricing-card-button">Contact Sales</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

//<button onclick='redirectPage("standard")'>Add To Card</button>

export default Pricing