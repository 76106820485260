import React from "react";
import CounterUp from "../CounterUp"

const Stats = () => {
    return (
        <section className="stats" id="stats">
            <h2 className="stat-header">Stats</h2>
            <div className="stats-container">
                {/*
                <div className="stat-item">
                    <h2>{<CounterUp target={2} unit={"+"}/>}</h2>
                    <p>Customers</p>
                </div>
                */}
                <div className="stat-item">
                    <h2>{<CounterUp target={100} unit={"K"}/>}</h2>
                    <p>Total Detections</p>
                </div>
                <div className="stat-item">
                    <h2>{<CounterUp target={10} unit={"M+"}/>}</h2>
                    <p>Total App Installations</p>
                </div>
            </div>
        </section>
    )
}

export default Stats