import React from "react";

import logo from "../../assets/rocket.png"

const Contact = () => {
    return (
        <section className="contact-new wow fadeIn" id="about-us">
            <div className="contact-new-container">
                <section className="about">
                    <div className="about-content">
                        <h2>Interested in using Skyforce? <br /> Contact Us</h2>
                        <p>
                            If you are a game developer or publisher interested in integrating Skyforce anti-cheat into your game please don't hesitate to contact us.
                        </p>
                        <div className="contact-center">
                            <a href="/contact" className="read-more">Contact Us</a>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default Contact