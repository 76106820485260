import React from "react";

import YoutubeEmbed from "../YoutubeEmbed"

const Preview = () => {
    return (
        <section className="preview" id="preview">
            <div className="heading">
                <h1>Preview</h1>
            </div>
            <div className="preview-container">
                <YoutubeEmbed embedId="YBbwCYIooOk" />
            </div>
        </section>
    )
}

export default Preview