import React from "react"
import Header from "../../compontents/blog/Header"
import Footer from "../../compontents/blog/Footer"

const BlogNotFound = () => {
    return (
        <div className="layout">
            <Header />
                <h1 className="notfound">The page you are looking for doesn't exist :(</h1>
            <Footer />
        </div>
    )
}

export default BlogNotFound